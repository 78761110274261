$(document).ready(function() {

    $('.js-mobile-menu').on('click', function() {
        $(this).toggleClass('active');
    });

    // header fixed

    let header = $('header.header');

    $(window).scroll(function() {
        if ($(window).scrollTop() > header.innerHeight()) {
            header.addClass('fixed');
        } else {
            header.removeClass('fixed');
        }
    });

    // слайдеры

    $('.js-catalog-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            loop: true,
            loopedSlides: 3,
            spaceBetween: 20,
            centeredSlides: true,
            observer: true,
            observeParents: true,
            watchOverflow: false,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    loop: false,
                    centeredSlides: false,
                },
                992: {
                    spaceBetween: 40,
                    loop: false,
                    centeredSlides: false,
                },
            },
        });
    });

    $('.js-goods-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            // loop: true,
            spaceBetween: 20,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            // lazy: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                992: {
                    slidesPerView: 4,
                },
                1200: {
                    spaceBetween: 24,
                    slidesPerView: 5,
                },
            },
        });
    });

    $('.js-catalog-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            loop: true,
            loopedSlides: 3,
            spaceBetween: 20,
            centeredSlides: true,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    spaceBetween: 40,
                    loop: true,
                    loopedSlides: 3,
                    centeredSlides: true,
                },
                992: {
                    spaceBetween: 40,
                    slidesPerView: 3,
                    loop: false,
                    loopedSlides: 0,
                    centeredSlides: false,
                },
            },
        });
    });

    $('.js-commodities-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            loop: true,
            loopedSlides: 3,
            spaceBetween: 20,
            centeredSlides: true,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    spaceBetween: 40,
                    loop: true,
                    loopedSlides: 3,
                    centeredSlides: true,
                },
                992: {
                    spaceBetween: 46,
                    slidesPerView: 3,
                    loop: false,
                    loopedSlides: 0,
                    centeredSlides: false,
                },
            },
        });
    });

    $('.js-prices-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            loop: true,
            loopedSlides: 3,
            centeredSlides: true,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    loop: true,
                    loopedSlides: 3,
                    centeredSlides: true,
                },
                992: {
                    slidesPerView: 3,
                    loop: false,
                    loopedSlides: 0,
                    centeredSlides: false,
                },
            },
        });
    });

    $('.js-view-gallery').each(function() {

        var swiperThumbs = new Swiper($(this).find('.js-view-gallery-thumb .js-slider-container')[0], {
            slidesPerView: 4,
            spaceBetween: 16,
            watchOverflow: false,
            observer: true,
            observeParents: true,
        });
        var swiperTop = new Swiper($(this).find('.js-view-gallery-top .js-slider-container')[0], {
            slidesPerView: 1,
            slidesPerGroup: 1,
            watchOverflow: true,
            observer: true,
            observeParents: true,
            effect: 'fade',
            pagination: {
                el: $(this).find('.js-slider-pagination')[0],
                type: "fraction",
            },
            thumbs: {
                swiper: swiperThumbs
            }
        });
    });

    $('.js-product-gallery').each(function() {

        var swiperThumbs = new Swiper($(this).find('.js-product-gallery-thumb .js-slider-container')[0], {
            slidesPerView: 6,
            spaceBetween: 12,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            direction: 'vertical',
        });
        var swiperTop = new Swiper($(this).find('.js-product-gallery-top .js-slider-container')[0], {
            slidesPerView: 1,
            slidesPerGroup: 1,
            watchOverflow: true,
            observer: true,
            observeParents: true,
            effect: 'fade',
            pagination: {
                el: $(this).find('.js-slider-pagination')[0],
                type: "fraction",
            },
            thumbs: {
                swiper: swiperThumbs
            }
        });
    });

    $('.js-product-item-slider').each(function() {

        var swiperThumbs = new Swiper($(this).find('.js-product-item-slider-thumb')[0], {
            slidesPerView: 'auto',
            observer: true,
            observeParents: true,
        });
        var swiperTop = new Swiper($(this).find('.js-product-item-slider-top .js-slider-container')[0], {
            slidesPerView: 1,
            // spaceBetween: 1000,
            autoHeight: true,
            observer: true,
            observeParents: true,
            watchOverflow: false,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            // breakpoints: {
            //     768: {
            //         slidesPerView: 3,
            //         spaceBetween: 20,
            //         loop: false,
            //         centeredSlides: false,
            //     },
            //     992: {
            //         spaceBetween: 40,
            //         loop: false,
            //         centeredSlides: false,
            //     },
            // },
            thumbs: {
                swiper: swiperThumbs
            }
        });
    });

    $('.js-team-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 4,
                },
            },
        });
    });

    $('.js-partners-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    slidesPerView: 4,
                },
                992: {
                    slidesPerView: 5,
                },
            },
        });
    });

    $('.js-comparsion-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 1,
            allowTouchMove: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
        });
    });

    $('.js-cases-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                992: {
                    slidesPerView: 3,
                },
            },
        });
    });

    $('.js-gallery-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            spaceBetween: 12,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                992: {
                    spaceBetween: 24,
                    slidesPerView: 5,
                },
            },
        });
    });

    $('.js-case-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            spaceBetween: 24,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
            },
        });
    });

    $('.js-reviews-block-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 1,
            autoHeight: true,
            allowTouchMove: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
        });
    });

    $('.js-reviews-section-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                    slidesPerView: 2,
                },
            },
        });
    });

    $('.js-video-reviews-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            spaceBetween: 20,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    spaceBetween: 0,
                    slidesPerView: 3,
                },
            },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                    slidesPerView: 4,
                },
            },
        });
    });

    $('.js-screen-reviews-slider').each(function() {
        var swiper = new Swiper($(this).find('.js-slider-container')[0], {
            slidesPerView: 'auto',
            spaceBetween: 20,
            watchOverflow: false,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: $(this).find('.js-slide-next')[0],
                prevEl: $(this).find('.js-slide-prev')[0],
            },
            breakpoints: {
                768: {
                    spaceBetween: 0,
                    slidesPerView: 3,
                },
            },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                    slidesPerView: 4,
                },
            },
        });
    });

    $.fancybox.defaults.backFocus = false;


    // modals start

    function scrollbarWidth() {
        var block = $('<div>').css({ 'height': '50px', 'width': '50px' }),
            indicator = $('<div>').css({ 'height': '200px' });

        $('body').append(block.append(indicator));
        var w1 = $('div', block).innerWidth();
        block.css('overflow-y', 'scroll');
        var w2 = $('div', block).innerWidth();
        $(block).remove();
        return (w1 - w2);
    }

    function modalOpen(modal) {
        if (modal.length) {
            modal.fadeIn();
            $('body').addClass('lock').css('padding-right', scrollbarWidth());
        }
    }

    function modalClose(modal) {
        modal.fadeOut('fast');
        setTimeout(function() { $('body').removeClass('lock').removeAttr('style class'); }, 200);
    }

    $(document).on('mousedown', '[data-modal]', function(e) {
        e.preventDefault();
        modalOpen($('.' + $(this).attr('data-modal')));
    });

    $(document).on('click', '.js-modal-close', function(e) {
        e.preventDefault();
        modalClose($(this).closest('.modal'));
    });

    $(document).on('click', '.modal', function(e) {
        if (!$('.modal__inner').is(e.target) && $('.modal__inner').has(e.target).length === 0) {
            modalClose($(this));
        }
    });

    $(document).on('keydown', function(e) {
        if (e.keyCode === 27) {
            e.preventDefault();
            modalClose($('.modal'));
        }
    });
    // modals end

    $('.js-select').each(function() {
        var select = $(this);
        var btn = $(this).find('.js-select-btn');
        var list = $(this).find('.js-select-list');

        btn.on('click', function() {
            list.slideToggle('fast');
            select.toggleClass('active');
        });

        $('.js-select input').on('change', function() {
            var value = $(this).siblings('label').text();
            $(this).closest('.js-select').find('.js-select-btn').text(value);
            $(this).closest('.js-select').find('.js-select-list').slideUp('fast');
            $('.js-select').removeClass('active');
        })
    });

    $(document).on('click', function(e) {
        if (!$('.js-select').is(e.target) && $('.js-select').has(e.target).length === 0) {
            $('.js-select-list').slideUp('fast');
            $('.js-select').removeClass('active');
        }
    })

    $(document).on('keydown', function(e) {
        if (e.keyCode === 27) {
            $('.js-select-list').slideUp('fast');
            $('.js-select').removeClass('active');
        }
    });

    function showText() {
        var showText = document.querySelectorAll('.js-show-text');

        if (showText) {
            showText.forEach(function(it) {
                var rows = parseInt(it.getAttribute('data-rows')) ? parseInt(it.getAttribute('data-rows')) : 5;
                $clamp(it, { clamp: rows });
            });
        }

        $('.js-show-text-btn').each(function() {
            var initialText = $(this).find('span').text();

            $(this).on('click', function() {

                $(this).parent().toggleClass('show');
                $(this).toggleClass('active');
                $(this).hasClass('active') ? $(this).find('span').text('Свернуть') : $(this).find('span').text(initialText);
                $(this).closest('.swiper-wrapper').toggleClass('active');
            });
        });
    }

    showText();

    //маски

    $('input[type="tel"]').each(function() {
        var mask = IMask(this, { mask: '+7 (000) 000-00-00', lazy: false });
    });

    $('input').blur(function() {
        if ($('input:text').is(":empty")) {
            $(this).parents('.form-control').addClass('active');
        }
    });

    $('.js-accordeon .js-accordeon-toggler').on('click', f_acc);

    function f_acc() {
        //скрываем все кроме того, что должны открыть
        $('.js-accordeon .js-accordeon-content').not($(this).next()).slideUp('fast');
        $('.js-accordeon .js-accordeon-toggler').not($(this)).removeClass('active');
        // открываем или скрываем блок под заголовоком, по которому кликнули
        $(this).next().slideToggle();
        $(this).toggleClass('active');
    }

    $('.js-submenu').on('click', function(e) {
        var link = $(this).find('span');
        var submenu = $(this).find(' > ul');
        var toggler = $(this);
        if (!link.is(e.target) && link.has(e.target).length === 0) {
            e.preventDefault();
            submenu.slideToggle();
            toggler.toggleClass('active');
        }
    })

    $('.js-menu-toggler').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.js-mobile-menu').slideToggle();
        if ($(this).hasClass('active')) {
            $('body').toggleClass('lock').css('padding-right', scrollbarWidth());
        } else {
            setTimeout(function() { $('body').removeClass('lock').removeAttr('style class'); }, 200);
        }
    });

    $('table').each(function() {
        var table = $(this);
        if (table.length) {
            $(this).before('<div class="table-container"></div>');
            $(this).siblings('.table-container').append($(this));
            var contW = $(this).parent('.table-container').width();
            var tableW = $(this).width();
            if (contW < tableW) {
                $(this).parent('.table-container').addClass('scroll');
            } else {
                $(this).parent('.table-container').removeClass('scroll');
            }

            $(window).resize(function() {
                if (contW < tableW) {
                    $(this).parent('.table-container').addClass('scroll');
                } else {
                    $(this).parent('.table-container').removeClass('scroll');
                }
            });
        }
    });

});

document.addEventListener('DOMContentLoaded', function() {
    Cocoen.create(document.querySelector('.cocoen'));
    Cocoen.parse(document.body);
});

$(document).ready(function() {
    /*----------------------------------
      Ymap start
  -----------------------------------*/
    var spinner = $('.ymap-container').children('.loader');
    var check_if_load = 0;
    var myMap, myPlacemark;


    function init() {
        var myMapTemp = new ymaps.Map("map", {
            center: [44.05226807598319, 43.045867000000015],
            zoom: 17,
            controls: ['zoomControl', 'fullscreenControl']
        });

        var myMapTemp2 = new ymaps.Map("map-yandex", {
            center: [44.05226807598319, 43.045867000000015],
            zoom: 17,
            controls: ['zoomControl', 'fullscreenControl']
        });

        var myMapTemp3 = new ymaps.Map("yandex-map", {
            center: [44.05226807598319, 43.045867000000015],
            zoom: 17,
            controls: ['zoomControl', 'fullscreenControl']
        });

        var myPlacemarkTemp = new ymaps.Placemark([44.05226807598319, 43.045867000000015]);
        var myPlacemarkTemp2 = new ymaps.Placemark([44.05226807598319, 43.045867000000015]);
        var myPlacemarkTemp3 = new ymaps.Placemark([44.05226807598319, 43.045867000000015]);

        myMapTemp.geoObjects.add(myPlacemarkTemp);

        myMapTemp.behaviors.disable('scrollZoom');

        myMapTemp2.geoObjects.add(myPlacemarkTemp2);

        myMapTemp2.behaviors.disable('scrollZoom');

        myMapTemp3.geoObjects.add(myPlacemarkTemp3);

        myMapTemp3.behaviors.disable('scrollZoom');

        if (window.screen.width <= 768) {
            myMapTemp.behaviors.disable('drag');
            myMapTemp2.behaviors.disable('drag');
            myMapTemp3.behaviors.disable('drag');
        }

        //Получаем первый экземпляр коллекции слоев, потом первый слой коллекции
        var layer = myMapTemp.layers.get(0).get(0);
        var layer2 = myMapTemp2.layers.get(0).get(0);
        var layer3 = myMapTemp2.layers.get(0).get(0);

        //Решение по callback-у для определния полной загрузки карты: http://ru.stackoverflow.com/questions/463638/callback-загрузки-карты-yandex-map
        waitForTilesLoad(layer).then(function() {
            //Скрываем
            spinner.removeClass('is-active');
        });
        waitForTilesLoad(layer2).then(function() {
            //Скрываем
            spinner.removeClass('is-active');
        });

        waitForTilesLoad(layer3).then(function() {
            //Скрываем
            spinner.removeClass('is-active');
        });
    }


    function waitForTilesLoad(layer) {
        return new ymaps.vow.Promise(function(resolve, reject) {
            var tc = getTileContainer(layer),
                readyAll = true;
            tc.tiles.each(function(tile, number) {
                if (!tile.isReady()) {
                    readyAll = false;
                }
            });
            if (readyAll) {
                resolve();
            } else {
                tc.events.once("ready", function() {
                    resolve();
                });
            }
        });
    }

    function getTileContainer(layer) {
        for (var k in layer) {
            if (layer.hasOwnProperty(k)) {
                if (
                    layer[k] instanceof ymaps.layer.tileContainer.CanvasContainer ||
                    layer[k] instanceof ymaps.layer.tileContainer.DomContainer
                ) {
                    return layer[k];
                }
            }
        }
        return null;
    }

    function loadScript(url, callback) {

        var script = document.createElement("script");

        if (script.readyState) { //IE
            script.onreadystatechange = function() {
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else { //Другие браузеры
            script.onload = function() {
                callback();
            };
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }

    var ymap = function() {
        // $('.ymap-container').mouseenter(function() {
        //     if (check_if_load == 0) {
        //         check_if_load = 1;

        //         spinner.addClass('is-active');

        //         loadScript("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;loadByRequire=1", function() {
        //             ymaps.load(init);
        //         });
        //     }
        // });

        $(window).scroll(function() {
            $('.ymap-container').each(function() {
                if ($(window).scrollTop() > $('.ymap-container').offset().top - 700 && !$('.ymap-container').hasClass('is-active')) {
                    $('.ymap-container').addClass('is-active');
                    if (check_if_load == 0) {
                        check_if_load = 1;

                        spinner.addClass('is-active');

                        var flagMapApi = false;

                        if (!flagMapApi) {
                            flagMapApi = true;
                            loadScript("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;loadByRequire=1", function() {
                                ymaps.load(init);
                            });
                        }
                    }
                }
            });
        });
    }
    //Map Yandex
    ymap();

});